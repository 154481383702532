<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">资源管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a"
            >平台课程（运营）</a
          >
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="课程名称" class="searchboxItem ci-full">
              <span class="itemLabel">课程名称:</span>
              <el-input
                v-model="courseName"
                type="text"
                size="small"
                placeholder="请输入课程名称"
                clearable
              />
            </div>
            <span title="培训类型" class="searchboxItem ci-full flexcc">
              <span class="itemLabel">培训类型:</span>
              <tree
                ref="tree"
                @eventBtn="childBack"
                :ruleForm="ruleForm"
                size="small"
                @clearParams="clearParams"
                modal
                typeStu
              />
            </span>
            <div title="资源提供者" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 7em">资源提供者:</span>
              <el-select
                size="small"
                v-model="compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
              >
                <el-option
                  v-for="item in CompanyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
            <div title="审核状态" class="searchboxItem ci-full">
              <span class="itemLabel">审核状态:</span>
              <el-select
                v-model="auditState"
                placeholder="请选择"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in auditStateList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="售卖状态" class="searchboxItem ci-full">
              <span class="itemLabel">售卖状态:</span>
              <el-select
                v-model="salesState"
                placeholder="请选择"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in salesStateList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox">
            <div title="可见区域" class="searchboxItem ci-full">
              <span class="itemLabel">可见区域:</span>
              <el-select
                v-model="visibleLimitType"
                placeholder="请选择"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in visibleLimitTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div
              title="区域"
              class="searchboxItem ci-full"
              v-if="visibleLimitType == 20"
            >
              <span class="itemLabel">区域:</span>
              <el-cascader
                class="flex1"
                size="small"
                :options="areatreeList"
                v-model="visibleAreaIds"
                :props="propsareall"
                placeholder="请选择区域"
                clearable
              ></el-cascader>
            </div>
            <div
              title="机构名称"
              class="searchboxItem ci-full"
              v-if="visibleLimitType == 30"
            >
              <span class="itemLabel">机构名称:</span>
              <el-select
                size="small"
                v-model="visibleCompId"
                remote
                :remote-method="getCompanyList2"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
              >
                <el-option
                  v-for="item in CompanyList2"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="getData()"
              >查询</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                width="100px"
                fixed
              />
              <el-table-column
                label="课程名称"
                align="left"
                prop="courseName"
                show-overflow-tooltip
                minWidth="250"
                fixed
              />
              <el-table-column
                label="培训类型"
                align="left"
                prop="trainTypeName"
                show-overflow-tooltip
                minWidth="200"
              />
              <el-table-column
                label="标签"
                align="left"
                prop="courseTag"
                show-overflow-tooltip
                width="100"
              />
              <el-table-column
                label="培训等级"
                align="left"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.trainLevelName || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="讲师"
                align="left"
                prop="teacherName"
                show-overflow-tooltip
                minWidth="180"
              />
              <el-table-column
                label="总学时"
                align="right"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.lessonNum || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="课件数量"
                align="right"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.kpointNum || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="课时标准"
                align="right"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.convertClassHour? $setDictionary("CONVERT_CLASS_HOUE", scope.row.convertClassHour) : "45分钟" }}
                </template>
              </el-table-column>
              <el-table-column
                label="视频时长"
                align="right"
                show-overflow-tooltip
                minWidth="120"
              >
                <template slot-scope="scope">{{
                  getTime(scope.row.kpointTotalDuration)
                }}</template>
              </el-table-column>
              <el-table-column
                label="总课时"
                align="right"
                show-overflow-tooltip
                minWidth="120"
              >
                <template slot-scope="scope"
                  >{{ scope.row.totalClassHours || 0 }}课时</template
                >
              </el-table-column>
              <el-table-column
                label="区域"
                align="left"
                show-overflow-tooltip
                minWidth="150"
                prop="areaName"
              >
                <template slot-scope="scope">{{
                  scope.row.areaName || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="可见机构"
                align="left"
                prop="visibleCompNames"
                show-overflow-tooltip
                minWidth="200"
                >
                <template slot-scope="scope">
                  {{ scope.row.visibleCompNames || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="售卖状态"
                align="left"
                show-overflow-tooltip
                minWidth="150"
                prop="salesState"
              >
                <template slot-scope="scope">
                  {{ $setDictionary("SELLSTATE", scope.row.salesState) }}
                </template>
              </el-table-column>
              <el-table-column
                label="课程创建时间"
                align="left"
                show-overflow-tooltip
                minWidth="150"
                prop="createTime"
              >
                <template slot-scope="scope">{{
                  scope.row.createTime | moment
                }}</template>
              </el-table-column>
              <el-table-column
                label="资源提供者"
                align="left"
                show-overflow-tooltip
                minWidth="260"
              >
                <template slot-scope="scope">{{
                  scope.row.compName || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="审核状态"
                align="center"
                show-overflow-tooltip
                width="100"
              >
                <template slot-scope="scope">{{
                  $setDictionary("AUDITSTATE", scope.row.auditState) ==
                  "审核未通过"
                    ? "未通过"
                    : $setDictionary("AUDITSTATE", scope.row.auditState) ==
                      "审核通过"
                    ? "已通过"
                    : $setDictionary("AUDITSTATE", scope.row.auditState)
                }}</template>
              </el-table-column>
              <el-table-column
                label="备注"
                align="left"
                show-overflow-tooltip
                minWidth="150"
                prop="remark"
              >
                <template slot-scope="scope">{{
                  scope.row.remark || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                minWidth="240"
                fixed="right"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleLook(scope.row.courseId)"
                    >查看详情</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="qualification(scope.row.courseId)"
                    >讲师资质</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="uploadCoverFile(scope.row.courseId)"
                    >课程封面</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="uploadOutlineFile(scope.row.courseId)"
                    >课程大纲</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="uploadKpointOutline(scope.row)"
                    >下载大纲</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="downloadAll(scope.row.courseId)"
                    >全部下载</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="changeCourseTag(scope.row)"
                    >标签</el-button
                  >
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <!-- 设置可见区域 -->
    <el-dialog
      title="设置可见区域"
      :visible.sync="dialogVisible"
      width="50%"
      top="15%"
      :center="true"
      :before-close="doClose"
      :close-on-click-modal="false"
    >
      <span>可见区域：</span>
      <div style="padding-bottom: 1rem" class="seeArea">
        <el-radio-group v-model="radio" @change="radioChange">
          <el-radio label="1">全部区域可见</el-radio>
          <el-radio label="2">部分区域可见</el-radio>
        </el-radio-group>
      </div>
      <div v-show="casaderShow" class="df">
        <el-cascader
          class="flex1"
          :options="areatreeList"
          v-model="areaall"
          :props="propsareall"
          placeholder="请选择可见区域"
          clearable
          filterable
          @change="change"
        ></el-cascader>
        <el-button style="height: 40px" class="bgc-bv" @click="doall"
          >全部</el-button
        >
      </div>
      <span
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: center"
      >
        <el-button @click="doClose()" class="bgc-bv">取消</el-button>
        <el-button class="bgc-bv" @click="Sure()">确定</el-button>
      </span>
    </el-dialog>
    <!-- 课程封面 - loading -->
    <el-dialog
      title="课程封面"
      :visible.sync="uploadcoverfiledialog"
      width="45%"
      class="toVoidLoding"
    >
      <div style="margin: 10 0 10px">
        <div>
          <el-upload
            class="upload-demo upload-btn upload-btndrag"
            :action="actionUrl"
            :on-error="handleError"
            :on-success="handleSuccess"
            :on-change="uploadChange1"
            :show-file-list="false"
            :auto-upload="false"
            drag
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
          </el-upload>
        </div>
        <div
          v-if="uploadfileList.length"
          style="margin-top: 50px; margin-left: 10px"
        >
          当前选择文件：
          <div style="display: flex; flex-direction: column">
            <span
              style="color: #409eff"
              v-for="(item, index) in uploadfileList"
              :key="index"
            >
              {{ item.fileName }}
              <!-- <a @click="reomveExl" style="color: #5bb5ff;margin:0 15px">预览</a> -->
              <a @click="lookExl(item)" style="color: #409eff; margin: 0 15px"
                >预览</a
              >
              <a @click="removeExl(index)" style="color: red; margin: 0 15px"
                >删除</a
              >
            </span>
          </div>
        </div>
        <div v-else style="margin-top: 50px; margin-left: 10px">
          <p style="margin-top: 10px; margin-left: 10px">未选择文件</p>
        </div>
      </div>
      <div style="height: 20px">
        <span class="dialog-footer" style="float: right">
          <el-button
            type="primary"
            size="small"
            class="bgc-bv"
            round
            @click="uploadfileClose"
            >取 消</el-button
          >
          <el-button
            type="primary"
            size="small"
            class="bgc-bv"
            round
            @click="uploadfileDetermine"
            >确 定</el-button
          >
        </span>
      </div>
      <el-divider></el-divider>
      <div>
        <el-table
          ref="multipleTable"
          :data="uploadfileNewList"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
        >
          <el-table-column label="序号" align="center" type="index" />
          <el-table-column
            label="操作人"
            align="left"
            show-overflow-tooltip
            prop="operatorName"
          >
            <template slot-scope="scope">
              {{ scope.row.operatorName }}
            </template>
          </el-table-column>
          <el-table-column
            label="上传时间"
            align="left"
            show-overflow-tooltip
            prop="createTime"
          >
            <template slot-scope="scope">
              {{ scope.row.createTime | moment }}
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <div slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                @click="getInfo(scope.row.fileUrl)"
                >查看</el-button
              >
            </div>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <!-- 课程大纲-->
    <el-dialog
      title="课程大纲"
      :visible.sync="uploadOutlinefiledialog"
      width="45%"
      class="toVoidLoding"
    >
      <div style="margin: 10 0 10px">
        <div>
          <el-upload
            class="upload-demo upload-btn upload-btndrag"
            :action="actionUrl"
            :on-error="handleError"
            :on-success="handleSuccess"
            :on-change="uploadChange2"
            :show-file-list="false"
            :auto-upload="false"
            drag
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
          </el-upload>
        </div>
        <div
          v-if="uploadfileOutlineList.length"
          style="margin-top: 50px; margin-left: 10px"
        >
          当前选择文件：
          <div style="display: flex; flex-direction: column">
            <span
              style="color: #409eff"
              v-for="(item, index) in uploadfileOutlineList"
              :key="index"
            >
              {{ item.fileName }}
              <!-- <a @click="reomveExl" style="color: #5bb5ff;margin:0 15px">预览</a> -->
              <a @click="lookExl(item)" style="color: #409eff; margin: 0 15px"
                >预览</a
              >
              <a @click="removeExl(index)" style="color: red; margin: 0 15px"
                >删除</a
              >
            </span>
          </div>
        </div>
        <div v-else style="margin-top: 50px; margin-left: 10px">
          <p style="margin-top: 10px; margin-left: 10px">未选择文件</p>
        </div>
      </div>
      <div style="height: 20px">
        <span class="dialog-footer" style="float: right">
          <el-button
            type="primary"
            size="small"
            class="bgc-bv"
            round
            @click="uploadfileClose"
            >取 消</el-button
          >
          <el-button
            type="primary"
            size="small"
            class="bgc-bv"
            round
            @click="uploadfileDetermine2"
            >确 定</el-button
          >
        </span>
      </div>
      <el-divider></el-divider>
      <div>
        <el-table
          ref="multipleTable"
          :data="uploadfileNewOutLineList"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
        >
          <el-table-column label="序号" align="center" type="index" />
          <el-table-column
            label="操作人"
            align="left"
            show-overflow-tooltip
            prop="operatorName"
          >
            <template slot-scope="scope">
              {{ scope.row.operatorName }}
            </template>
          </el-table-column>
          <el-table-column
            label="上传时间"
            align="left"
            show-overflow-tooltip
            prop="createTime"
          >
            <template slot-scope="scope">
              {{ scope.row.createTime | moment }}
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" fixed="right">
            <div slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                @click="getInfo2(scope.row.courseFileId)"
                >查看</el-button
              >
            </div>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <!-- 上传附件预览 - loading -->
    <el-dialog
      title="预览"
      :visible.sync="uploadLoding"
      @close="uploadLodClose"
      width="50%"
      top="2%"
      center
    >
      <div class="ovy-a">
        <div id="pdf-cert2" style="height: 600px"></div>
      </div>
    </el-dialog>
    <!-- 讲师资质-->
    <el-dialog
      title="讲师资质"
      :visible.sync="instructorQualification"
      width="40%"
      top="1%"
      :center="true"
      :before-close="doClose"
      :close-on-click-modal="false"
    >
      <div>
        <el-table
          ref="multipleTable"
          :data="eduteacherdtoList"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
        >
          <el-table-column label="序号" align="center" type="index" />
          <el-table-column
            label="讲师"
            align="left"
            show-overflow-tooltip
            prop="operatorName"
          >
            <template slot-scope="scope">
              {{ scope.row.teacherName }}
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <div slot-scope="scope">
              <el-button
                type="text"
                size="mini"
                style="padding: 0 5px"
                @click="addLecturer(scope.row.teacherId)"
                >查看</el-button
              >
            </div>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <!-- 设置标签 -->
    <el-dialog
      title="设置标签"
      :visible.sync="changeCourseTagData.dialogVisible"
      width="400px"
    >
      <el-form
        :model="changeCourseTagData"
        :rules="changeCourseTagData.rules"
        ref="changeCourseTagData"
        label-width="60px"
        class="demo-ruleForm"
        size="small"
      >
        <el-form-item label="标签" prop="courseTag">
          <el-input v-model="changeCourseTagData.courseTag"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          class="bgc-bv"
          @click="changeCourseTagData.dialogVisible = false"
          >取 消</el-button
        >
        <el-button
          class="bgc-bv"
          type="primary"
          @click="changeCourseTagDetermine"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
import { resetKeepAlive } from "@/utils/common";
import pdf from "pdfobject";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "platformCourses",
  components: {
    Empty,
    PageNum,
    tree,
  },
  mixins: [List],
  data() {
    return {
      courseId: "", // 课程id
      actionUrl: "",
      courseName: "",
      compId: "",
      radio: "1",
      casaderShow: false,
      showtip: false,
      areatreeList: [],
      auditStateList: [],
      auditState: "30",
      salesStateList: [],
      salesState: "",
      areaall: "",
      propsareall: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
        multiple: true,
      },
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      dialogVisible: false,
      CompanyList: [],
      CompanyList2: [],
      visibleLimitType: "",
      // 可见区域
      visibleLimitTypeList: [
        {
          value: "10",
          label: "全部区域课件",
        },
        {
          value: "20",
          label: "部分区域可见",
        },
        {
          value: "30",
          label: "指定机构可见",
        },
      ],
      visibleAreaIds: "", // 部分区域可见-地区id
      visibleCompId: "", // 指定机构可见-机构id
      compType: "",
      params: {},
      ruleForm: {
        Trainingtype: "",
      },
      isall: false, // 课件区域用的字段（我也不知道干啥的）
      uploadcoverfiledialog: false, // 课程封面 - 弹框
      uploadOutlinefiledialog: false, // 课程大纲 - 弹框
      uploadfileList: [], // 课程封面 - 上传文件数据集合
      uploadfileNewList: [], // 课程封面 - 列表数据
      uploadfileOutlineList: [], // 课程大纲 - 上传文件数据集合
      uploadfileNewOutLineList: [], // 课程大纲 - 列表数据
      uploadLoding: false, // 上传附件预览 - 弹框
      instructorQualification: false, // 讲师资质 - 弹框
      eduteacherdtoList: [], // 讲师资质弹窗 - 讲师列表
      // 设置标签 - 弹框数据
      changeCourseTagData: {
        dialogVisible: false, // 弹框状态
        courseId: "", // 课程id
        courseTag: "", // 标签
        // 弹框数据校验
        rules: {
          courseTag: [
            { required: true, message: "请输入标签", trigger: "blur" },
          ],
        },
      },
    };
  },
  created() {
    this.getareatree();
    this.getsalesStateList();
    this.getauditStateList();
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  methods: {
    // 讲师资质 - 获取讲师列表
    qualification(courseId) {
      this.instructorQualification = true;
      let parmar = {
        courseId: courseId,
      };
      this.$post("/platform/resources/course/get-teacher", parmar)
        .then((ret) => {
          this.eduteacherdtoList = ret.data;
        })
        .catch((err) => {
          return;
        });
    },
    // 讲师资质 - 获取讲师列表 - 查看
    addLecturer(teacherId) {
      this.instructorQualification = false;
      this.$router.push({
        path: "/web/lecturerYwEdit",
        query: { id: teacherId, stu: "edit" },
      });
    },
    // 课程封面 & 课程大纲 - 取消
    uploadfileClose() {
      this.uploadOutlinefiledialog = false;
      this.uploadcoverfiledialog = false;
    },
    // 课程封面 - 确定
    uploadfileDetermine() {
      for (let i = 0; i < this.uploadfileList.length; i++) {
        this.uploadfileList[i].docType = "10";
        this.uploadfileList[i].courseId = this.courseId;
      }
      if (this.uploadfileList.length == 0) {
        let parmar = {
          courseId: this.courseId,
          docType: "10",
        };
        this.$post("/platform/resources/course/del-file", parmar)
          .then((ret) => {
            if (ret.status == "0") {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.uploadcoverfiledialog = false;
              this.fileremark = "";
              this.uploadfileList = [];
            }
          })
          .catch((err) => {
            return;
          });
      } else {
        this.$post("/platform/resources/course/save-file", this.uploadfileList)
          .then((ret) => {
            if (ret.status == "0") {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.uploadcoverfiledialog = false;
              this.fileremark = "";
              this.uploadfileList = [];
            }
          })
          .catch((err) => {
            return;
          });
      }
    },
    // 课程大纲 - 确定
    uploadfileDetermine2() {
      for (let i = 0; i < this.uploadfileOutlineList.length; i++) {
        this.uploadfileOutlineList[i].docType = "20";
        this.uploadfileOutlineList[i].courseId = this.courseId;
      }
      if (this.uploadfileOutlineList.length == 0) {
        let parmar = {
          courseId: this.courseId,
          docType: "20",
        };
        this.$post("/platform/resources/course/del-file", parmar)
          .then((ret) => {
            if (ret.status == "0") {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.uploadcoverfiledialog = false;
              this.uploadOutlinefiledialog = false;
              this.fileremark = "";
              this.uploadfileList = [];
            }
          })
          .catch((err) => {
            return;
          });
      } else {
        this.$post(
          "/platform/resources/course/save-file",
          this.uploadfileOutlineList
        )
          .then((ret) => {
            if (ret.status == "0") {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.uploadcoverfiledialog = false;
              this.uploadOutlinefiledialog = false;
              this.fileremark = "";
              this.uploadfileList = [];
            }
          })
          .catch((err) => {
            return;
          });
      }
    },
    // 课程大纲打开弹窗
    uploadOutlineFile(courseId) {
      this.uploadOutlinefiledialog = true;
      this.courseId = courseId;
      let parmar = {
        courseId: courseId,
        docType: "20",
      };
      this.$post("/platform/resources/course/get-file", parmar)
        .then((ret) => {
          if (ret.status == "0") {
            this.uploadfileOutlineList = ret.data;
          }
        })
        .catch((err) => {
          return;
        });
      this.$post("/platform/resources/course/get-file", parmar)
        .then((ret) => {
          if (ret.status == "0") {
            this.uploadfileNewOutLineList = ret.data;
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 课程大纲 - 上传附件
    uploadChange2(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const doc = extension === "doc";
      const docx = extension === "docx";
      // const isXLS = extension === "XLS";
      // const isxls = extension === "xls";
      if (!doc && !docx) {
        this.$message.error("只能上传后缀是.doc或.docx的文件");
        return;
      }
      if (size > 10) {
        this.$message.error("文件大小不能超过10M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.uploadfileOutlineList.push({
            courseId: this.courseId,
            fileKey: result.data.fileKey,
            fileType: extension,
            fileUrl: result.data.fileURL,
            fileName: file.name,
          });
        })
        .catch((err) => {
          return;
        });
    },
    // 课程封面
    uploadCoverFile(courseId) {
      this.uploadcoverfiledialog = true;
      this.courseId = courseId;
      let parmar = {
        courseId: courseId,
        docType: "10",
      };
      this.$post("/platform/resources/course/get-file", parmar)
        .then((ret) => {
          if (ret.status == "0") {
            this.uploadfileList = ret.data;
          }
        })
        .catch((err) => {
          return;
        });
      this.$post("/platform/resources/course/get-file", parmar)
        .then((ret) => {
          if (ret.status == "0") {
            this.uploadfileNewList = ret.data;
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 课程封面 - 删除
    removeExl(index) {
      this.uploadfileList.splice(index, 1);
      this.uploadfileOutlineList.splice(index, 1);
      this.$forceUpdate();
    },
    // 课程封面 - 预览
    lookExl(item) {
      this.uploadLoding = true;
      this.$nextTick(() => {
        pdf.embed(item.fileUrl, "#pdf-cert2");
      });
    },
    // 课程封面 - 查看
    getInfo(fileUrl) {
      this.uploadLoding = true;
      this.$nextTick(() => {
        pdf.embed(fileUrl, "#pdf-cert2");
      });
    },
    // 课程封面 - 上传封面
    uploadChange1(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const jpg = extension === "jpg";
      const png = extension === "png";
      // const isXLS = extension === "XLS";
      // const isxls = extension === "xls";
      if (!jpg && !png) {
        this.$message.error("只能上传后缀是.jpg或.png的文件");
        return;
      }
      if (size > 10) {
        this.$message.error("文件大小不能超过10M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.uploadfileList.push({
            courseId: this.courseId,
            fileKey: result.data.fileKey,
            fileType: extension,
            fileUrl: result.data.fileURL,
            fileName: file.name,
          });
        })
        .catch((err) => {
          return;
        });
    },
    // 课程大纲 - 查看
    getInfo2(courseFileId) {
      this.$post("/platform/resources/course/down-doc", { courseFileId })
        .then((ret) => {
          if (ret.status == "0") {
            window.open(ret.message);
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 培训类型
    childBack(params) {
      this.params = { ...params };
    },
    // 培训类型
    clearParams() {
      this.params = {};
    },
    // 获取区划数据
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    // 查看详情
    handleLook(courseId) {
      this.$router.push({
        path: "/web/detailResource",
        query: {
          courseId,
          stu: "setLook",
        },
      });
    },
    // 可见区域选择
    radioChange(val) {
      if (val == "2") {
        this.casaderShow = true;
      } else {
        this.casaderShow = false;
      }
    },
    // 导出课表
    ExportTimetablePdf(courseId) {
      this.$post("run/course/export/platformcoursepdf", { courseId })
        .then((ret) => {
          if (ret.status == "0") {
            window.open(ret.data);
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 导出课表Excel
    ExportTimetableExcel(courseId) {
      this.$post("/run/course/export/platformcourse", { courseId })
        .then((ret) => {
          if (ret.status == "0") {
            window.open(ret.data.url);
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 获取字典 - 审核状态
    getauditStateList() {
      const auditStatelist = this.$setDictionary("AUDITSTATE", "list");
      const list = [{ label: "全部", value: "" }];
      for (const key in auditStatelist) {
        if (auditStatelist[key] == "审核通过") {
          list.push({
            value: key,
            label: "已通过",
          });
        } else if (auditStatelist[key] == "审核未通过") {
          list.push({
            value: key,
            label: "未通过",
          });
        } else {
          list.push({
            value: key,
            label: auditStatelist[key],
          });
        }
      }
      this.auditStateList = list;
    },
    // 获取字典 - 售卖状态
    getsalesStateList() {
      const salesState = this.$setDictionary("SELLSTATE", "list");
      const list = [];
      for (const key in salesState) {
        list.push({
          value: key,
          label: salesState[key],
        });
      }
      this.salesStateList = list;
    },
    // 选择机构
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 选择机构
    getCompanyList2(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList2 = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 获取列表状态
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.courseName) {
        params.courseName = this.courseName;
      }
      if (this.compId) {
        params.compId = this.compId;
      }
      if (this.auditState) {
        params.auditState = this.auditState;
      }
      if (this.salesState) {
        params.salesState = this.salesState;
      }
      if (this.visibleLimitType) {
        params.visibleLimitType = this.visibleLimitType;
      }
      if (this.visibleAreaIds) {
        params.visibleAreaIds = this.visibleAreaIds;
      }
      if (this.visibleCompId) {
        params.visibleCompId = this.visibleCompId;
      }
      if (this.params.trainFormId) {
        params.trainTypeId = this.params.trainFormId;
      }
      this.doFetch({
        url: "/platform/resources/course/pageList",
        params,
        pageNum,
      });
    },
    // 设置可见区域
    handleSetVisibleArea(courseId) {
      this.courseId = courseId;
      this.dialogVisible = true;
      this.$post("/run/course/visible/area", { courseId })
        .then((ret) => {
          console.log(ret.data);
          if (ret.data.length > 0) {
            this.radio = "2";
            this.casaderShow = true;
            this.areaall = ret.data;
          } else {
            this.radio = "1";
            this.casaderShow = false;
            this.areaall = [];
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 设置可见区域 - 全部
    doall() {
      if (this.isall) {
        this.areaall = [];
        this.isall = false;
      } else {
        this.areaall = this.areatreeList.flatMap((el) => el.value);
        this.isall = true;
      }
    },
    // 设置可见区域 - 确定
    Sure() {
      const paramr = {
        courseId: this.courseId,
      };
      if (this.radio == "1") {
        paramr.areaArr = [];
      } else {
        paramr.areaArr = this.areaall;
      }
      this.$post("/run/course/setting/area", paramr)
        .then((ret) => {
          if (ret.status == "0") {
            this.$message({
              type: "success",
              message: "设置可见区域成功",
            });
            this.dialogVisible = false;
            this.radio = "1";
            this.casaderShow = false;
            this.areaall = [];
            this.getData(-1);
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 弹窗关闭(所有弹窗) - 写一起了 之前的代码 三年前了  别动~！！！
    doClose() {
      this.dialogVisible = false;
      this.instructorQualification = false;
      this.showLecturer = false;
      this.radio = "1";
      this.casaderShow = false;
    },
    // 处理时间
    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    },
    // 全部下载
    downloadAll(courseId) {
      this.$post("/platform/resources/course/asyncDownloadAll", { courseId })
        .then((res) => {
          if (res.status == "0") {
            let list = res.data;
            if (!this.downloadItems.includes(list.taskId)) {
              this.$store.dispatch("pushDownloadItems", list.taskId);
            } else {
              this.$message.warning(
                "[" + list.fileName + "]已经申请下载,请耐心等待"
              );
            }
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 下载大纲
    uploadKpointOutline(row) {
      this.$post("/platform/resources/course/asyncDownloadCourseOutline", {
        courseId: row.courseId,
        courseName: row.courseName,
      })
        .then((res) => {
          if (res.status == "0") {
            let list = res.data;
            if (!this.downloadItems.includes(list.taskId)) {
              this.$store.dispatch("pushDownloadItems", list.taskId);
            } else {
              this.$message.warning(
                "[" + list.fileName + "]已经申请下载,请耐心等待"
              );
            }
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 设置标签 - 弹框
    changeCourseTag(item) {
      this.changeCourseTagData.courseId = item.courseId;
      this.changeCourseTagData.courseTag = item.courseTag || "";
      this.changeCourseTagData.dialogVisible = true;
    },
    // 设置标签 - 弹框 - 确定
    changeCourseTagDetermine() {
      this.$refs["changeCourseTagData"].validate((valid) => {
        if (valid) {
          this.$post("/platform/resources/course/save/courseTag", {
            courseId: this.changeCourseTagData.courseId,
            courseTag: this.changeCourseTagData.courseTag,
          })
            .then((res) => {
              this.$message.success("操作成功");
              this.getData(this.pageNum);
              this.$refs["changeCourseTagData"].clearValidate();
              this.changeCourseTagData.dialogVisible = false;
            })
            .catch((err) => {});
        }
      });
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scoped>
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.seeArea {
  .el-radio-group {
    display: flex;
    flex-direction: column;

    .el-radio :last-child {
      margin-top: 1rem;
    }
  }
}

.searchbox {
  padding: 0;
}

.cascader {
  position: relative;
  width: 100%;
  z-index: 10;
}

.tp {
  width: 50%;
  float: left;

  .img-el-upload {
    width: 63%;
    float: left;
    margin-right: 2%;
  }

  .tswz {
    float: left;
    font-size: 12px;
    line-height: 25px;
    color: #909399;

    .t1 {
      font-size: 14px;
      line-height: 40px;
      color: #606266;
    }
  }
}

.form-box {
  padding: 10px 20px;

  .form {
    min-width: 600px;
    padding: 20px;
  }

  .form-bg {
    background-color: rgb(247, 248, 253);
  }
}

.bannerBox .el-form-item__content .hide /deep/.el-upload--picture-card {
  display: none;
}
</style>
